function ContainerBox(props) {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="d-block flex-fill container-default">
                <div className="px-4 pt-1 pt-md-5 w-100">
                    <div className="row">
                        <div className="col-md-12">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContainerBox;