import React from 'react'
import { useTable, usePagination, useGlobalFilter } from "react-table"

function Table({ columns, data, length = true, search = true, titulo = '' }) {

    const props = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 20 }

        },
        useGlobalFilter,
        usePagination,

    );
    const {
        getTableBodyProps,
        rows,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = props;
    React.useEffect(() => {
    }, [globalFilter]);

    return (
        <>
            <section className="w-100 mt-3">
                <div className="d-flex flex-column">
                    <div className="row d-flex align-items-center justify-content-between">
                        <div className="col-md-8">
                            <div className="inner-addon right-addon">
                                <i className="d-flex bi bi-search" />
                                <input
                                    value={globalFilter || ""}
                                    onChange={e => setGlobalFilter(e.target.value)} type="search"
                                    placeholder="Pesquise pelo nome desejado...."
                                    className="form-control py-2"
                                />
                            </div>
                        </div>
                        <div className="d-flex mt-2 mt-md-0 align-items-center justify-content-end col-md-4">
                            <select className="form-select py-2" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                                <option disabled>Quantidade</option>
                                {[20, 50, 100, 500, 1000].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </section>
            <div className="table-full-width mt-3 mb-3">
                <div className="table-responsive">
                    <table className="table align-middle mt-1">
                        <thead>
                            <tr>
                                <th colSpan="5" className='bg-primary text-white rounded-top'>{titulo}</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr className='border border-white text-nowrap'>
                                <th className='text-uppercase text-black-50 fw-medium fs-8 bg-muted'>#</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Arquivo</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Usuário</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Data</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Ações</th>
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='text-nowrap' {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="7" className='bg-primary text-white rounded-bottom'>
                                    <div className="table-footer d-flex justify-content-between align-items-center">
                                        <span>Exibindo {pageSize < rows.length ? pageSize : rows.length} de {rows.length} registros</span>
                                        <div>
                                            <span>Página {pageIndex + 1} de {pageOptions.length}</span>
                                            <button className="btn btn-outline-primary btn-sm ms-2 text-white" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                <i className="bi bi-caret-left"></i>
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm ms-1 text-white" onClick={() => nextPage()} disabled={!canNextPage}>
                                                <i className="bi bi-caret-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

}
export default Table;