import { useState, useEffect, useMemo } from "react";
import TableCertificado from "../../Components/TableCertificados";
import { formatDateTime } from "../../Components/jwtUtils";
import Api from "../../Auth/Api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GerarCertificado from "../../Components/GerarCertificado";

function Certificado() {

    const token = JSON.parse(localStorage.getItem("user_token"));

    //listar todos os cursos
    const [dataTodosCursos, setDataTodosCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-todos-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTodosCursos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // listar certificados
    const [dataCertificados, setDataCertificados] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-certificados-aluno`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCertificados(response.data);
            } catch (error) {
                setDataCertificados([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // Filtro para seleção por curso
    const [selectCurso, setSelectCurso] = useState(null);
    const FilterDataProvas = selectCurso ? dataCertificados.filter(item => item.idCurso === selectCurso) : dataCertificados;

    console.log(FilterDataProvas);

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'icon',
                accessor: row => row.id,
                Cell: () => (
                    <div className="d-flex align-items-center justify-content-center">
                        <i className="bi bi-card-checklist fs-3"></i>
                    </div>
                )
            },
            {
                Header: 'Curso',
                accessor: 'nomeCurso'
            },
            {
                Header: 'Gestor',
                accessor: 'nomeGestor'
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'data',
                accessor: row => row.data,
                Cell: ({ cell: { value }, row: { original } }) => (
                    formatDateTime(value)
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center">
                        <PDFDownloadLink document={<GerarCertificado data={{ id: original.id, nomeAluno: original.nomeAluno, nomeCurso: original.nomeCurso, prazo_validade: original.prazo_validade, data:original.data, nomeGestor:original.nomeGestor, certificado:original.certificado, nomeTopico: original.nomeTopico, cargaHorariaTotal:original.cargaHorariaTotal }} />} fileName={`certificado_${original.nomeAluno}_${original.nomeCurso}.pdf`}>
                            <button onClick={() => { }} className="btn btn-outline-primary rounded px-2">
                                <i className="bi bi-download" />
                            </button>
                        </PDFDownloadLink>
                    </div>

                )
            }
        ],
        []
    )

    return (
        <>
            <div className="fade-in w-100">
                <span className="text-primary fs-1">Certificados</span>

                <p className="fs-6">
                    Lista com todos os seus certificados disponíveis
                </p>

                <TableCertificado columns={columns} data={FilterDataProvas} filterSelect={dataTodosCursos} search={false} setSelectCurso={setSelectCurso} titulo="Lista de Certificados" />
            </div>
        </>
    )
}

export default Certificado;
