import { useEffect, useState } from 'react';

function SemPermissao() {
    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(timer);
                    localStorage.clear();
                    window.location.href = '/login';
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <>
            <div className="fade-in w-100 mb-5">
                <span className="text-primary fs-1">Ops!</span>
                <p className="fs-6">Você não tem permissão para acessar essa página</p>
                <p className="fs-6">Saindo da plataforma em {countdown} segundos</p>
            </div>
        </>
    )
}

export default SemPermissao;