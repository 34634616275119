import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Api from "../Auth/Api";
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Modal, Toast } from 'react-bootstrap';
import { decodeJWT, formatDateTime } from '../Components/jwtUtils';
import Select from 'react-select';

function Chat() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const messagesEndRef = useRef(null);

    const [conversations, setConversations] = useState([]);
    const [currentConversation, setCurrentConversation] = useState(null);
    const [messageText, setMessageText] = useState(""); // Estado para gerenciar o valor do input de mensagem
    const [ws, setWs] = useState(null);
    const [showModalAddChat, setShowModalAddChat] = useState(false);
    const [dataUsuariosChat, setDataUsuariosChat] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [currentChatId, setCurrentChatId] = useState(null);
    const [toast, setToast] = useState({})
    const [showToast, setShowToast] = useState(false)
    const [shownDateTimeId, setShownDateTimeId] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [filteredConversations, setFilteredConversations] = useState([]);


    // listar cursos
    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);


    // Obter os novos usuarios da conversa
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-chat`, { headers: { Authorization: `Bearer ${token}` } });
                setDataUsuariosChat(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, currentChatId]);


    const handleCourseChange = (selectedOption) => {
        setSelectedCourse(selectedOption);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filtrar os novos usuarios da tabela 
    const filteredUsuariosChat = dataUsuariosChat.filter(user => {
        // Filtro pelo nome do usuário
        const isNameMatch = user.nome_completo.toLowerCase().includes(searchTerm.toLowerCase());

        // Se não houver curso selecionado, retorne apenas o resultado do filtro pelo nome
        if (!selectedCourse) return isNameMatch;

        // Converta a string 'permissao_cursos' em um array e verifique se contém o ID do curso selecionado.
        const userCourses = JSON.parse(user.permissao_cursos || "[]");
        const isCourseMatch = userCourses.includes(selectedCourse.value);

        // Retorne verdadeiro se ambos, o nome e o curso, corresponderem
        return isNameMatch && isCourseMatch;
    });

    // Mudar o valor do input da mensagem de iniciar um chat
    const handleInputChange = (e, userId) => {
        setInputValues(prevState => ({
            ...prevState,
            [userId]: e.target.value
        }));
    }


    const initiateChatWith = async (id, token, message) => {

        const data = {
            id: id,
            message: message
        }

        try {
            const response = await Api.post(`api/iniciar-chat/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
            if (response.data && response.data.idConversa) {
                setCurrentChatId(response.data.idConversa);
            }
        } catch (error) {
            console.error(error.response.data.message);
            setToast({ header: 'Ops!', body: error.response.data.message })
            setShowToast(true)
        }
    }

    useEffect(() => {
        if (currentChatId) {
            handleConversationClick(currentChatId);
            setShowModalAddChat(false);
        }
        // eslint-disable-next-line
    }, [currentChatId]);

    // Conectar ao WebSocket
    useEffect(() => {

        const rws = new ReconnectingWebSocket(process.env.REACT_APP_SOCKET);

        rws.addEventListener('open', () => {
            // console.log('Conectado ao WebSocket');
        });

        rws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            // Atualize a conversa com a nova mensagem
            if (currentChatId) {
                setCurrentConversation(prevState => {
                    return {
                        ...prevState,
                        messages: [...prevState.messages, message]
                    };
                });
            }
        });

        rws.addEventListener('close', () => {
            // console.log('Desconectado do WebSocket');
        });

        rws.addEventListener('error', (error) => {
            // console.error('Erro no WebSocket:', error);
        });

        // Define o WebSocket no estado
        setWs(rws);

        return () => {
            rws.close();
        };


    }, [currentChatId]);

    // Obter as conversas 
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/conversas`, { headers: { Authorization: `Bearer ${token}` } });
                setConversations(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, currentChatId]);

    // Filtrar as conversas pelo select do cursos
    useEffect(() => {

        const filtered = conversations.filter(conversation => {
            if (!selectedCourse) return true; // Se nenhum curso estiver selecionado, mostre todos os usuários.

            // Converta a string 'permissao_cursos' em um array e verifique se contém o ID do curso selecionado.
            const isCourseMatch = JSON.parse(conversation.permissao_cursos || "[]"); // O "[]" padrão é usado para evitar erros se for nulo ou indefinido.
            return isCourseMatch.includes(selectedCourse.value);
        });


        setFilteredConversations(filtered);
    }, [conversations, selectedCourse]);


    console.log(filteredConversations);
    // Seleciona a conversa e exibe na tela
    const handleConversationClick = async (conversationId) => {
        try {
            const response = await Api.get(`api/conversas/${conversationId}`, { headers: { Authorization: `Bearer ${token}` } });
            // Se a conversa é nova e não tem mensagens, definimos um formato padrão.
            if (!response.data.id) {
                setCurrentConversation({
                    id: "",
                    nome_completo: "",
                    messages: []
                });
            } else {
                setCurrentConversation(response.data);
            }
        } catch (error) {
            console.error(error.response.data.message);
        }
    };

    // Voltar para a lista de conversas
    const handleBackClick = () => {
        setCurrentConversation(null);
    };

    // Enviar a mensagem
    const sendMessage = async () => {
        try {
            const data = {
                idConversa: currentConversation.id,
                text: messageText
            };
            await Api.post('/api/enviar-mensagem', data, { headers: { Authorization: `Bearer ${token}` } });
            setMessageText(""); // Limpar o campo de input após enviar a mensagem
            handleConversationClick(currentConversation.id); // Atualizar a conversa após enviar a mensagem
            ws.send(JSON.stringify(data));
        } catch (error) {
            // console.error(error.response.data.message);
        }
    };

    // Rolar a lista de mensagens para a mais recente
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight);
        }
    }, [currentConversation]);


    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        container: (base) => ({
            ...base,
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "35px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };


    return (
        <>

            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <div className="h-100 bg-white rounded p-3 mt-3">
                    {!currentConversation && (
                        <div className="row">

                            <div className='col-12 col-md-6'>
                                {nivel !== 'Aluno' &&
                                    <Select
                                        options={dataCursos.map((value) => {
                                            return {
                                                value: value.id,
                                                label: `${value.nome}`,
                                            };
                                        })}
                                        styles={customStyles}
                                        placeholder="Selecione o curso"
                                        isSearchable
                                        isClearable
                                        onChange={handleCourseChange}
                                        value={selectedCourse}
                                        noOptionsMessage={() => "Nenhum curso encontrado."}
                                    />
                                }
                            </div>
                            <div className='col-12 col-md-6 mb-2 mt-md-0 mt-2 text-end'>
                                <button
                                    className='btn btn-primary text-white col-12 col-md-3'
                                    onClick={() => setShowModalAddChat(true)}
                                > Novo chat
                                </button>
                            </div>

                            <div className={`overflow-auto mt-3 ${filteredConversations.length === 0 ? 'd-none' : ''}`}>
                                <div className="list-group list-group-flush">
                                    {Array.isArray(filteredConversations) && filteredConversations.map(conversation => (
                                        <Link key={conversation.id} onClick={() => handleConversationClick(conversation.id)} className="list-group-item list-group-item-action">{conversation.nome_completo} {conversation.chat_aberto === 1 && <span className='ms-3 fs-8 text-primary'>Mensagem não lida</span>}</Link>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {currentConversation && (
                        <div className="col-12 d-flex flex-column h-100">
                            <div className="d-flex align-items-center px-3 py-2 justify-content-between border-bottom">
                                <h6 className="mb-0">{currentConversation.name}</h6>
                                <span className="ml-auto cursor-pointer" onClick={handleBackClick}>
                                    <i className="bi bi-x-lg"></i>
                                </span>
                            </div>
                            <div className="chat-messages flex-grow-1 overflow-auto px-3 py-2 cursor-pointer" ref={messagesEndRef}>
                                {currentConversation.messages.map(message => (
                                    <div key={message.text} onClick={() => {
                                        if (shownDateTimeId === message.id) {
                                            setShownDateTimeId(null);
                                        } else {
                                            setShownDateTimeId(message.id);
                                        }
                                    }} className={`${message.type === 'sent' ? 'text-end' : ''} mb-3`}>
                                        <div className={`d-inline-block text-start p-2 rounded ${message.type === 'sent' ? 'bg-primary text-white' : 'bg-gray-300 text-dark'}`}>
                                            {message.text}
                                        </div>
                                        {shownDateTimeId === message.id && (
                                            <div className={`d-block mt-1 ${message.type === 'sent' ? 'text-end' : 'text-start'} fs-7`}>
                                                {formatDateTime(message.timestamp)}
                                            </div>
                                        )}

                                    </div>

                                ))}
                            </div>
                            <div className="px-3 py-2 border-top">
                                <div className="input-group">
                                    <input type="text" value={messageText} onChange={e => setMessageText(e.target.value)} onKeyUp={e => e.key === 'Enter' && sendMessage(e.target.value)} className="form-control" placeholder="Escreva uma mensagem..." />
                                    <button className="btn btn-primary" type="button" onClick={sendMessage}>
                                        <i className="bi bi-cursor text-white"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Modal size='lg' show={showModalAddChat} onHide={() => setShowModalAddChat(false)}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Iniciar um novo chat</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <div className="input-group mb-1">
                                    <input type="text" className="form-control" placeholder="Buscar usuário..." onChange={handleSearchChange} />
                                    <span className="input-group-text"><i className="bi bi-search"></i></span>
                                </div>
                                <div className="scrollable-table" style={{ maxHeight: 400 }}>
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                            {filteredUsuariosChat.map(user => (
                                                <tr key={user.id}>
                                                    <td>{user.nome_completo}<br /><span className='fs-7'>{user.unidade}</span></td>
                                                    <td>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Digite sua mensagem'
                                                            value={inputValues[user.id] || ''}
                                                            onChange={e => handleInputChange(e, user.id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <div className='d-flex justify-content-end'>
                                                            <button className="btn btn-primary text-white" onClick={() => initiateChatWith(user.id, token, inputValues[user.id])}>Iniciar Chat</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Chat;