import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../Auth/Api';

function Resetar() {
    const navigate = useNavigate();
    const [mensagem, setMensagem] = useState('Aguarde...');
    const [sucesso, setSucesso] = useState(false);
    const [codigo, setCodigo] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const codigo = params.get('codigo');
        if (codigo) {
            setCodigo(codigo);
        } else {
            setMensagem('Código de redefinição de senha não encontrado.');
        }
    }, []);

    useEffect(() => {
        const resetarSenha = async () => {
            if (!codigo) return;

            try {
                await Api.post(`/resetar/${codigo}`);
                setMensagem('Senha resetada com sucesso!');
                setSucesso(true);
            } catch (error) {
                setMensagem('Não foi encontrado uma solicitação de recuperação de senha, tente novamente.');
                setSucesso(false);
                console.error(error.response?.data?.message || error.message);
            }
        };

        resetarSenha();
    }, [codigo]);

    return (
        <>
            <nav className="navbar bg-primary">
                <div className="container-fluid">
                    <span className="d-flex justify-content-center w-100 navbar-brand m-5px">
                        <img src="./../assets/logo-white.png" className="cursor-pointer" alt="" />
                    </span>
                </div>
            </nav>
            <div className="fade-in w-100">
                <div className="container-fluid login-container">
                    <div className="row align-items-center justify-content-start">
                        <div className="col-12 col-md-12 col-xl-6 mx-md-5">
                            <div className="d-flex flex-column px-4">
                                <div className="d-flex flex-column mb-5">
                                    <h2 className="text-dark fs-4 mb-3">{mensagem}</h2>
                                    {sucesso && (
                                        <p className="text-dark mb-4">Por favor, verifique seu email para obter os detalhes de acesso.</p>
                                    )}
                                </div>
                                <div className="d-flex flex-column gap-4">
                                    <button onClick={() => navigate('/')} className="btn btn-primary text-light px-4 py-1 py-md-3">
                                        Voltar para página inicial
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block col p-0 spacing-img"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resetar;