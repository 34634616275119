import { useState, useEffect, useMemo } from "react";
import TableCertificado from "../Components/TableCertificados";
import { formatDateTime } from "../Components/jwtUtils";
import Api from "../Auth/Api";
import { pdf } from "@react-pdf/renderer";
import GerarCertificado from "../Components/GerarCertificado";
import { useCallback } from "react";

function Certificados() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const [selectedTipo, setSelectedTipo] = useState('favoritos');

    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    //listar todos os cursos
    const [dataTodosCursos, setDataTodosCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-todos-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTodosCursos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // listar certificados
    const [dataCertificados, setDataCertificados] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-certificados/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCertificados(response.data);
            } catch (error) {
                setDataCertificados([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, selectedTipo])

    // Filtro para seleção por curso
    const [selectCurso, setSelectCurso] = useState(null);
    const [selectUnidade, setSelectUnidade] = useState(null);
    const FilterDataProvas = selectCurso ? dataCertificados.filter(item => item.idCurso === selectCurso) : dataCertificados;
    const FilterDataUnidade = selectUnidade ? FilterDataProvas.filter(item => item.unidade === selectUnidade) : FilterDataProvas;

    const unidades = useMemo(() => {
        const allUnidades = dataCertificados.map(item => item.unidade);
        return [...new Set(allUnidades)].map(unidade => ({ value: unidade, label: unidade }));
    }, [dataCertificados]);

    // Gerar PDF
    const loadPDFData = useCallback(async (data) => {
        try {
            // Buscar os dados para gerar o PDF
            const response = await Api.get(`api/info-certificado/${data}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Gerar o PDF e obter como Blob
            const blob = await pdf(<GerarCertificado data={{ id: response.data[0].id, nomeAluno: response.data[0].nomeAluno, prazo_validade: response.data[0].prazo_validade, nomeCurso: response.data[0].nomeCurso, data: response.data[0].data, nomeGestor: response.data[0].nomeGestor, certificado: response.data[0].certificado, nomeTopico: response.data[0].nomeTopico, cargaHorariaTotal: response.data[0].cargaHorariaTotal }} />).toBlob();

            // Criar uma URL para o Blob e abrir em uma nova aba
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            console.error("Erro ao gerar o PDF:", error.message || error);
        }
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'foto',
                accessor: row => row.foto,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            {value ? <img className="object-fit-cover" src={`${process.env.REACT_APP_API_URL}/fotos/${value}`} alt="Profile" style={{ height: '42px', width: '42px' }} /> : <i className="bi bi-person-fill-check fs-3"></i>}
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nomeAluno',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        <div className=''>{value}</div>
                        <div className='badge bg-primary'>{original.codigo}</div>
                        <div className='badge bg-success ms-1'>{original.unidade}</div>
                    </>
                )
            },
            {
                Header: 'Curso',
                accessor: 'nomeCurso'
            },
            {
                Header: () => 'Gestor', // Sem título para esta coluna
                id: 'Gestor',
                accessor: row => row.nomeGestor,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fs-6">
                        {original.idGestor !== 0 ? value : 'Automático'}
                    </div>
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'data',
                accessor: row => row.data,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="fs-7 text-center text-nowrap">
                        {formatDateTime(value)}
                    </div>
                )
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            onClick={() => loadPDFData(original.id)}
                            className="btn btn-outline-primary text-primary rounded px-2"
                        > <i className="bi bi-box-arrow-in-down"></i>
                        </button>
                    </div>

                )
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
                visible: false
            }
        ],
        [loadPDFData]
    )

    return (
        <>
            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Certificados</span>
                <p className="fs-6">Lista com todos os certificados disponíveis</p>
                <TableCertificado 
                    columns={columns} 
                    data={FilterDataUnidade} 
                    filterSelect={dataTodosCursos} 
                    setSelectCurso={setSelectCurso} 
                    filterTipo={filterTipo} 
                    setSelectedTipo={setSelectedTipo} 
                    setSelectUnidade={setSelectUnidade} 
                    unidades={unidades}
                    titulo="Lista de Certificados" 
                />
            </div>
        </>
    )
}

export default Certificados;